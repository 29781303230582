import axios, { AxiosError } from 'axios';

export const apiOrigin = 'https://edumap-api.wimcorp.dev/api/admin';

export const API_ROUTE = {
  login: '/login',
  partners: {
    list: '/list/search',
    getPartnersListByKeyword: '/partners/list/search?',
  },
  academy: {
    updateAcademyRegisterPrice: '/academy/data',
    getAcademyListByKeyword: '/academy/list/search?',
  },
};

export type BasicApiResponseType<T> = {
  data: T;
  message: string;
  config: {
    response: boolean;
    path: string;
    timestamp: string;
  };
};

export function reqeustGet<T>(url: string, header: object) {
  return new Promise<T>((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
      })
      .then((res) => resolve(res.data as T))
      .catch((err) => reject(err));
  });
}

export function reqeustSecureGet<T>(
  url: string,
  header: object,
  token: string,
) {
  return new Promise<T>((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          AUTHORIZATION: `Bearer ${token}`,
          ...header,
        },
      })
      .then((res) => resolve(res.data as T))
      .catch((err) => reject(err));
  });
}

export function reqeustPost<T>(url: string, header: object, body: object) {
  return new Promise<T>((resolve, reject) => {
    axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
      })
      .then((res) => resolve(res.data as T))
      .catch((err: AxiosError) => reject(err));
  });
}

export function reqeustSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
) {
  return new Promise<T>((resolve, reject) => {
    axios
      .post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          AUTHORIZATION: `Bearer ${token}`,
          ...header,
        },
      })
      .then((res) => resolve(res.data as T))
      .catch((err) => reject(err));
  });
}

export function reqeustPut<T>(url: string, header: object, body: object) {
  return new Promise<T>((resolve, reject) => {
    axios
      .put(url, body, {
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
      })
      .then((res) => resolve(res.data as T))
      .catch((err) => reject(err));
  });
}

export function reqeustDelete<T>(url: string, header: object) {
  return new Promise<T>((resolve, reject) => {
    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
      })
      .then((res) => resolve(res.data as T))
      .catch((err) => reject(err));
  });
}
