import useListState from '@hooks/list/useListState';
import useToken from '@hooks/token/useToken';
import usePartnersApi from '@hooks/usePartnersApi';
import useWindowSize from '@hooks/window/useWindowSize';
import {
  apiOrigin,
  API_ROUTE,
  BasicApiResponseType,
  reqeustSecureGet,
} from '@lib/api';
import {
  PartnersType,
  UserType,
} from '@typedef/components/common/common.types';
import { listBodyItem } from '@typedef/components/partners/partners.types';
import { GetPartnersListResponseType } from '@typedef/hooks/api/use.partners.types';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Partners from '../Partners';

const partnersListDummy: PartnersType[] = [
  {
    uid: '',
    email: '',
    name: '',
    tel: '',
    image: '',
    create_date: '',
    academy_count: 0,
  },
];
const itemCnt = 10;

const PartnersContainer = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const [listBodyItemInfos, setListBodyItemInfos] = useState(partnersListDummy);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef() as RefObject<HTMLInputElement>;

  const loadList = useCallback(async () => {
    const searchQ = searchRef.current?.value
      ? `&keyword=${searchRef.current?.value}`
      : '';

    const {
      data,
      message,
      config: { response },
    } = await reqeustSecureGet<
      BasicApiResponseType<GetPartnersListResponseType>
    >(
      apiOrigin +
        API_ROUTE.partners.getPartnersListByKeyword +
        `count=${itemCnt}&page=${page}` +
        searchQ,
      {},
      token,
    );
    if (!response) {
      console.log(response, message);
      return;
    }

    setListBodyItemInfos(data.partners.list);
    setMaxPage(data.maxPage);
  }, [page, token]);

  const onSearchClicked = useCallback(() => {
    setPage(1);
    loadList();
  }, [loadList]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  return (
    <Partners
      setSearchText={setSearchText}
      searchText={searchText}
      listBodyItemInfos={listBodyItemInfos}
      page={page}
      setPage={setPage}
      maxPage={maxPage}
      searchRef={searchRef}
      onSearchClicked={onSearchClicked}
    />
  );
};

export default PartnersContainer;
