import useToken from '@hooks/token/useToken';
import {
  apiOrigin,
  API_ROUTE,
  BasicApiResponseType,
  reqeustPost,
} from '@lib/api';
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';

const LoginContainer = () => {
  const navigate = useNavigate();
  const { __updateToken } = useToken();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const onLoginClicked = useCallback(async () => {
    const { data, message, config } = await reqeustPost<
      BasicApiResponseType<{ token: string }>
    >(
      apiOrigin + API_ROUTE.login,
      {},
      {
        id,
        password,
      },
    );

    if (!config.response) {
      alert('아이디 또는 비밀번호를 확인해주세요');
      console.error('[api] login failed', message);
      return;
    }

    __updateToken(data.token);

    navigate('partners');
  }, [id, password, navigate, __updateToken]);

  return (
    <Login
      id={id}
      password={password}
      setId={setId}
      setPassword={setPassword}
      onLoginClicked={onLoginClicked}
    />
  );
};

export default LoginContainer;
