import { SidebarProps } from '@typedef/components/sidebar/sidebar.types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './css/index.css';

const SideBar = ({ sidebarList, onSideBarItemClicked }: SidebarProps) => {
  const { pathname } = useLocation();
  return (
    <div className="sidebar">
      <div className="image-container">
        <img
          className="banner-image"
          src="/assets/images/banner.png"
          alt="ic_banner"
        ></img>
      </div>
      {sidebarList.map((item, index) => {
        return (
          <button
            className={`sidebar-button ${
              pathname.includes(item.root) && 'active'
            }`}
            onClick={() => onSideBarItemClicked(index)}
          >
            {pathname.includes(item.root) ? (
              <div className="image-container">
                <img
                  className="image"
                  src={`/assets/images/${item.root}_active.png`}
                  alt="ic_sidebar"
                ></img>
              </div>
            ) : (
              <div className="image-container">
                <img
                  className="image"
                  src={`/assets/images/${item.root}.png`}
                  alt="ic_sidebar"
                ></img>
              </div>
            )}
            <span className="button-text">{item.title}</span>
          </button>
        );
      })}
    </div>
  );
};

export default SideBar;
