import useListState from '@hooks/list/useListState';
import useToken from '@hooks/token/useToken';
import useAcademyApi from '@hooks/useAcademyApi';
import useWindowSize from '@hooks/window/useWindowSize';
import {
  AcademyListItem,
  FilterListType,
} from '@typedef/components/Academy/academy.types';
import {
  AcademyMyListType,
  AcademyType,
  PartnersListType,
  VerifyStatusType,
} from '@typedef/components/common/common.types';
import {
  apiOrigin,
  API_ROUTE,
  BasicApiResponseType,
  reqeustGet,
  reqeustPut,
  reqeustSecureGet,
  reqeustSecurePost,
} from '@lib/api';
import React, { useCallback, useEffect, useState } from 'react';
import {
  GetAcademyListByKeywordResponseType,
  GetAcademyUserWithRegistrationType,
} from '@typedef/hooks/api/use.academy.types';
import { Navigate, useNavigate } from 'react-router-dom';
import Academy from '../Academy';
import { useRef } from 'react';
import { RefObject } from 'react';

const academyListDummy: AcademyType[] = [
  {
    uid: '',
    averagePrice: 0,
    images: [],
    name: '',
    comment: '',
    tel: '',
    kakaoId: '',
    address: {
      road: '',
      latitude: 0,
      longitude: 0,
    },
    basic: {
      tags: [],
      comment: '',
    },
    class: {
      list: [],
    },
    teacher: {
      list: [],
    },
    verify: {
      partnersUid: '',
      registerDate: '',
      status: 'default',
    },
  },
];

const filterDummy: FilterListType = [
  { status: 'approve-ready', isClicked: false },
  { status: 'approve-denied', isClicked: false },
  { status: 'approved', isClicked: false },
];

const itemCnt = 10;

const AcademyContainer = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { getAcademyRegisterPrice, updateAcademyRegisterPrice } =
    useAcademyApi();
  const [price, setPrice] = useState(0);
  const [account, setAccount] = useState('');
  const [priceComma, setPriceComma] = useState<string>('');
  const [filter, setFilter] = useState(filterDummy);
  const [priceEditMode, setPriceEditMode] = useState<'modify' | 'display'>(
    'display',
  );
  const [listBodyItemInfos, setListBodyItemInfos] = useState(academyListDummy);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const searchRef = useRef() as RefObject<HTMLInputElement>;

  const addComma = useCallback((num: number) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  }, []);

  const onPriceButtonClicked = useCallback(() => {
    if (priceEditMode === 'display') {
      setPriceEditMode('modify');
    } else {
      updateAcademyRegisterPrice(price, account);
      setPriceEditMode('display');
    }
  }, [price, priceEditMode, account]);

  const statusKorean = useCallback((status: VerifyStatusType) => {
    switch (status) {
      case 'approved':
        return '승인';
      case 'approve-denied':
        return '승인 거절';
      case 'approve-ready':
        return '승인 대기';
      default:
        return '';
    }
  }, []);

  const onFilterItemClicked = useCallback(
    (idx: number) => {
      const clone = [...filter];
      clone[idx].isClicked = !clone[idx].isClicked;
      setFilter(clone);
    },
    [filter],
  );

  const onListItemClicked = useCallback(async (item: AcademyType) => {
    if (item.verify?.status === 'approve-ready') {
      navigate(`/academy/verify/${item.uid}`);
    } else {
      navigate(`/academy/detail/${item.uid}`);
    }
  }, []);

  const loadList = useCallback(async () => {
    const searchQ = searchRef.current?.value
      ? `&keyword=${searchRef.current?.value}`
      : '';
    const option = filter
      .filter((item) => item.isClicked === true)
      .map((item) => {
        return item.status;
      })
      .join(' ');
    const optionQ = option !== '' ? `&option=${option}` : '';

    const {
      data,
      message,
      config: { response },
    } = await reqeustSecureGet<
      BasicApiResponseType<GetAcademyListByKeywordResponseType>
    >(
      apiOrigin +
        API_ROUTE.academy.getAcademyListByKeyword +
        `count=${itemCnt}&page=${page}` +
        searchQ +
        optionQ,
      {},
      token,
    );

    if (!response) {
      console.log(response, message);
      return;
    }

    if (data) {
      setListBodyItemInfos(data.partners.list);
      setMaxPage(data.maxPage);
    }
  }, [page, filter, token, searchText]);

  const onSearchClicked = useCallback(() => {
    setPage(1);
    loadList();
  }, [loadList]);

  const loadPriceAccount = useCallback(async () => {
    const data = await getAcademyRegisterPrice();
    if (data) {
      setPrice(data.price);
      setAccount(data.account);
    }
  }, []);

  useEffect(() => {
    setPriceComma(addComma(price));
  }, [addComma, price]);

  useEffect(() => {
    loadPriceAccount();
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  return (
    <Academy
      page={page}
      setPage={setPage}
      maxPage={maxPage}
      setSearchText={setSearchText}
      searchText={searchText}
      listBodyItemInfos={listBodyItemInfos}
      price={priceComma}
      setPrice={setPrice}
      account={account}
      setAccount={setAccount}
      onPriceButtonClicked={onPriceButtonClicked}
      onListItemClicked={onListItemClicked}
      priceEditMode={priceEditMode}
      filter={filter}
      setFilter={setFilter}
      onFilterItemClicked={onFilterItemClicked}
      statusKorean={statusKorean}
      onSearchClicked={onSearchClicked}
      searchRef={searchRef}
    />
  );
};

export default AcademyContainer;
