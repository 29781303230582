import useToken from '@hooks/token/useToken';
import useAcademyApi from '@hooks/useAcademyApi';
import { GetAcademyUserWithRegistrationType } from '@typedef/hooks/api/use.academy.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AcademyVerifyStatus from '../AcademyVerifyStatus';

const academyDummy: GetAcademyUserWithRegistrationType = {
  user: {
    uid: '',
    email: '',
    name: '',
    tel: '',
    image: '',
    create_date: '',
  },
  academy: {
    uid: '',
    averagePrice: 0,
    images: [''],
    name: '',
    comment: '',
    tel: '',
    kakaoId: '',
    address: {
      road: '',
      latitude: 0,
      longitude: 0,
    },
    basic: {
      tags: [],
      comment: '',
    },
    class: {
      list: [],
    },
    teacher: {
      list: [],
    },
    verify: {
      partnersUid: '',
      registerDate: '',
      businessLicense: '',
      academyRegisterCertification: '',
      status: 'default',
    },
  },
};

const AcademyVerifyStatusContainer = () => {
  const { token } = useToken();
  const { academyUid } = useParams();
  const navigate = useNavigate();
  const { getAcademyUserWithRegistration, updateAcademyRegistration } =
    useAcademyApi();

  const [academyDetailInfo, setAcademyDetailInfo] =
    useState<GetAcademyUserWithRegistrationType>(academyDummy);

  const onChangeStatusClicked = useCallback(
    async (register: string) => {
      if (academyUid) {
        updateAcademyRegistration(academyUid, register);
        navigate(-1);
      }
    },
    [academyUid, updateAcademyRegistration],
  );

  useEffect(() => {
    const loadAcademyUser = async () => {
      if (academyUid) {
        setAcademyDetailInfo(
          (await getAcademyUserWithRegistration(academyUid)) ?? academyDummy,
        );
      }
    };
    loadAcademyUser();
  }, [academyUid, getAcademyUserWithRegistration]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  return (
    <AcademyVerifyStatus
      academyDetailInfo={academyDetailInfo}
      onChangeStatusClicked={onChangeStatusClicked}
    />
  );
};

export default AcademyVerifyStatusContainer;
