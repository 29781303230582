import SideBarContianer from '@components/SideBar/containers/SideBarContianer';
import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import AcademyRouting from './components/AcademyRouting';
import PartnersRouting from './components/PartnersRouting';

const SubRoutingMain = () => {
  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: '#f3f2f7',
        minWidth: 'fit-content',
        minHeight: 'fit-content',
      }}
    >
      <SideBarContianer />
      <Switch>
        <Route path="/partners/*" element={<PartnersRouting />} />
        <Route path="/academy/*" element={<AcademyRouting />} />
      </Switch>
    </div>
  );
};

export default SubRoutingMain;
