import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CPageNation from '../CPageNation';
import { getPageList } from '../libs/getPageList';

type Props = {
  maxPage: number;
  onPageChanged: (page: number) => void;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
};

const CPageNationContainer = ({
  maxPage,
  onPageChanged,
  page,
  setPage,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageList = useMemo(() => {
    return getPageList(currentPage, maxPage);
  }, [currentPage, maxPage]);

  const onPageClicked = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onBackBtnClicked = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const onNextBtnClicked = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, maxPage));
  }, [maxPage]);

  useEffect(() => {
    onPageChanged(currentPage);
  }, [onPageChanged, currentPage]);

  useEffect(() => {
    if (setPage) {
      setPage(currentPage);
    }
  }, [setPage, currentPage]);

  return (
    <CPageNation
      currentPage={page ? page : currentPage}
      pageList={pageList}
      onPageClicked={onPageClicked}
      onBackBtnClicked={onBackBtnClicked}
      onNextBtnClicked={onNextBtnClicked}
    />
  );
};

export default CPageNationContainer;
