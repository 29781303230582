import LoginContainer from '@components/Login/container/LoginContainer';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from 'react-router-dom';
import SubRoutingMain from './sub/SubRoutingMain';

const RootNavigation = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" element={<LoginContainer />} />
        <Route path="*" element={<SubRoutingMain />} />
      </Switch>
    </Router>
  );
};

export default RootNavigation;
