import { AcademyDetailProps } from '@typedef/components/Academy/acadenyDetail.types';
import React from 'react';
import './css/detail.css';

const AcademyDetail = ({
  academyDetailInfo,
  addComma,
  onChangeStatusClicked,
  onPartnersButtonClicked,
}: AcademyDetailProps) => {
  return (
    <div className="academy-detail">
      <div className="box">
        <div className="header">
          <div className="title-wrapper">
            <button
              className="partners-button"
              onClick={onPartnersButtonClicked}
            >
              {'파트너스 바로가기 '}
            </button>
            <div className="title">
              <span>{academyDetailInfo.academy.name}</span>
            </div>
            <div className="subtitle">
              {academyDetailInfo.academy.address.road}
            </div>
          </div>
          {academyDetailInfo.academy.verify?.status === 'approved' && (
            <div className="image-container">
              <img
                className="image"
                src="/assets/images/ic_approve.png"
                alt="ic_approve"
              />
            </div>
          )}
          {academyDetailInfo.academy.verify?.status === 'approve-denied' && (
            <div className="denied-academy">승인 거절된 학원입니다.</div>
          )}
        </div>
        {academyDetailInfo.academy.verify?.status === 'approved' && (
          <div className="verify-section">
            <div className="register-date">
              <span>승인 날짜 : </span>
              <span>
                {academyDetailInfo.academy.verify?.registerDate?.slice(0, 10)}
              </span>
            </div>
            <div className="section-body">
              <button
                className="approve"
                onClick={() => onChangeStatusClicked('approved')}
              >
                승인 갱신
              </button>
              <button
                className="refuse"
                onClick={() => onChangeStatusClicked('default')}
              >
                승인 취소
              </button>
            </div>
          </div>
        )}
        <div className="basic-info">
          <div className="title">기본정보</div>
          <div className="tags">
            {academyDetailInfo.academy.basic.tags
              .filter((item) => item !== '')
              .map((tag, index) => {
                return (
                  <div className="tag" key={index}>
                    <span># {tag}</span>
                  </div>
                );
              })}
          </div>
          <div className="text">{academyDetailInfo.academy.basic.comment}</div>
        </div>
        {/* 리스트 헤더 */}
        {academyDetailInfo.academy.verify?.status !== 'approve-denied' && (
          <div className="list-header">
            <div className="class-name text">강의제목</div>
            <div className="current text">현재인원</div>
            <div className="total text">총인원</div>
            <div className="price text">가격</div>
          </div>
        )}
        {/* 리스트 바디 */}
        {academyDetailInfo.academy.verify?.status !== 'approve-denied' && (
          <div className="list-body">
            {academyDetailInfo.academy.class?.list.map((item, index) => {
              return (
                <div className="list-items" key={index}>
                  <div className="class-name item">{item.name}</div>
                  <div className="current item">{item.currentPeople}</div>
                  <div className="total item">{item.maxPeople}</div>
                  <div className="price item">₩{addComma(item.price)}</div>
                </div>
              );
            })}
          </div>
        )}
        {/* 리스트 헤더 */}
        {academyDetailInfo.academy.verify?.status !== 'approve-denied' && (
          <div className="list-header">
            <div className="teacher-name text">강사이름</div>
            <div className="subject text">과목</div>
          </div>
        )}
        {/* 리스트 바디 */}
        {academyDetailInfo.academy.verify?.status !== 'approve-denied' && (
          <div className="list-body">
            {academyDetailInfo.academy.teacher?.list.map((teacher, index) => {
              return (
                <div className="list-items" key={index}>
                  <div className="teacher-name item">{teacher.name}</div>
                  <div className="subject item">{teacher.subject}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AcademyDetail;
