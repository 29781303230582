import CPageNationContainer from '@components/CPageNation/containers/CPageNationContainer';
import { academyProps } from '@typedef/components/Academy/academy.types';
import { partnersProps } from '@typedef/components/partners/partners.types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/list.css';

const Academy = ({
  page,
  maxPage,
  setSearchText,
  searchText,
  listBodyItemInfos,
  price,
  setPrice,
  account,
  setAccount,
  priceEditMode,
  onPriceButtonClicked,
  onListItemClicked,
  filter,
  setFilter,
  setPage,
  onFilterItemClicked,
  statusKorean,
  onSearchClicked,
  searchRef,
}: academyProps) => {
  return (
    <div className="academy">
      {/* 검색창 */}
      <div
        className="search-wrapper"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setSearchText(searchRef.current?.value ?? '');
            onSearchClicked();
          }
        }}
      >
        <div className="search-input-wrapper">
          <input
            className="search-input"
            type="text"
            id="inputId"
            placeholder={'학원명으로 검색해주세요'}
            autoComplete={'off'}
            ref={searchRef}
          />
          <div
            className="image-container"
            onClick={() => {
              setSearchText(searchRef.current?.value ?? '');
              onSearchClicked();
            }}
          >
            <img
              className="image"
              src="/assets/images/ic_search.png"
              alt="ic_icon"
            />
          </div>
        </div>

        {/* 금액산정 */}

        <div className="box">
          <div className="text-wrapper">
            <span className="title">금액</span>
            <div className="won-wrapper">
              {priceEditMode === 'modify' ? (
                <div className="price-account-input-wrapper">
                  <div>
                    <span className="won">￦</span>
                    <input
                      className="price-input"
                      value={price}
                      autoFocus
                      onChange={(e) =>
                        setPrice(
                          parseInt(e.target.value.replace(/[^0-9]/g, '')),
                        )
                      }
                    />
                  </div>
                  <input
                    placeholder="은행 계좌 정보를 적어주세요"
                    className="account-input"
                    value={account}
                    onChange={(e) => setAccount(e.target.value)}
                  />
                </div>
              ) : (
                <div className="price-account-wrapper">
                  <span className="price">￦{price}</span>
                  <span className="account">{account}</span>
                </div>
              )}
            </div>
          </div>
          {priceEditMode === 'modify' ? (
            <button className="end btn" onClick={onPriceButtonClicked}>
              완료하기
            </button>
          ) : (
            <button className="fix btn" onClick={onPriceButtonClicked}>
              수정하기
            </button>
          )}
        </div>
      </div>

      {/* 리스트 헤더 */}
      <div className="list-header">
        <div className="academy-name text">학원이름</div>
        <div className="address text">학원주소</div>
        <div className="lecture-num text">강의수</div>
        <div className="teacher-num text">강사수</div>
        <div className="status text">상태</div>
        <div className="register-date text">승인날짜</div>
      </div>

      {/* 리스트 바디 */}
      <div className="list-body">
        {listBodyItemInfos.map((item) => {
          return (
            <div
              className="list-items"
              key={item.uid}
              onClick={() => onListItemClicked(item)}
            >
              <div className="academy-name item">{item.name}</div>
              <div className="address item">{item.address.road}</div>
              <div className="lecture-num item">{item.class?.list.length}</div>
              <div className="teacher-num item">
                {item.teacher?.list.length}
              </div>
              <div className="status item">
                {statusKorean(item.verify?.status ?? 'default')}
              </div>
              <div className="register-date item">
                {item.verify?.registerDate?.slice(0, 10)}
              </div>
            </div>
          );
        })}
      </div>

      {/* 리스트 footer */}
      <div className="list-footer">
        {/* 필터 */}
        <div className="filter">
          <div className="check-box" onClick={() => onFilterItemClicked(0)}>
            <div className="check-icon">
              <img
                className="check-icon"
                src={`/assets/images/${
                  filter[0].isClicked ? 'ic_check.png' : 'ic_uncheck.png'
                }`}
                alt="ic_checkbox"
              />
            </div>
            <span>승인대기</span>
          </div>
          <div className="check-box" onClick={() => onFilterItemClicked(1)}>
            <div className="check-icon">
              <img
                className="check-icon"
                src={`/assets/images/${
                  filter[1].isClicked ? 'ic_check.png' : 'ic_uncheck.png'
                }`}
                alt="ic_checkbox"
              />
            </div>
            <span>승인거절</span>
          </div>
          <div className="check-box" onClick={() => onFilterItemClicked(2)}>
            <div className="check-icon">
              <img
                className="check-icon"
                src={`/assets/images/${
                  filter[2].isClicked ? 'ic_check.png' : 'ic_uncheck.png'
                }`}
                alt="ic_checkbox"
              />
            </div>
            <span>승인</span>
          </div>
        </div>
        {/* 리스트 페이지네이션 */}
        <CPageNationContainer
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          onPageChanged={setPage}
        />
      </div>
    </div>
  );
};

export default Academy;
