import {
  apiOrigin,
  API_ROUTE,
  BasicApiResponseType,
  reqeustSecureGet,
} from '@lib/api';
import {
  GetAcademyListByKeywordResponseType,
  GetAcademyUserWithRegistrationType,
} from '@typedef/hooks/api/use.academy.types';
import {
  GetPartnersDetailType,
  GetPartnersListResponseType,
} from '@typedef/hooks/api/use.partners.types';
import { useCallback } from 'react';
import useToken from './token/useToken';

export default function usePartnersApi() {
  const { token } = useToken();

  const getPartnersListAll = useCallback(
    async (count: number, page: number) => {
      const {
        data,
        message,
        config: { response },
      } = await reqeustSecureGet<
        BasicApiResponseType<GetPartnersListResponseType>
      >(
        apiOrigin +
          API_ROUTE.partners.getPartnersListByKeyword +
          `count=${count}&page=${page}`,
        {},
        token,
      );
      if (!response) {
        console.log(response, message);
        return;
      }

      return data;
    },
    [token],
  );

  const getPartnersListByKeyword = useCallback(
    async (count: number, page: number, keyword: string) => {
      const {
        data,
        message,
        config: { response },
      } = await reqeustSecureGet<
        BasicApiResponseType<GetPartnersListResponseType>
      >(
        apiOrigin +
          API_ROUTE.partners.getPartnersListByKeyword +
          `count=${count}&page=${page}&keyword=${keyword}`,
        {},
        token,
      );
      if (!response) {
        console.log(response, message);
        return;
      }

      return data;
    },
    [token],
  );

  const getPartnerDetail = useCallback(
    async (partnersUid: string) => {
      const {
        data,
        message,
        config: { response },
      } = await reqeustSecureGet<BasicApiResponseType<GetPartnersDetailType>>(
        apiOrigin + '/partners' + `/${partnersUid}`,
        {},
        token,
      );

      if (!response) {
        console.log(response, message);
        return;
      }

      return data;
    },
    [token],
  );

  return { getPartnersListAll, getPartnersListByKeyword, getPartnerDetail };
}
