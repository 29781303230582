import useToken from '@hooks/token/useToken';
import useAcademyApi from '@hooks/useAcademyApi';
import { AcademyType } from '@typedef/components/common/common.types';
import { GetAcademyUserWithRegistrationType } from '@typedef/hooks/api/use.academy.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AcademyDetail from '../AcademyDetail';

const academyDummy: GetAcademyUserWithRegistrationType = {
  user: {
    uid: '',
    email: '',
    name: '',
    tel: '',
    image: '',
    create_date: '',
  },
  academy: {
    uid: '',
    averagePrice: 0,
    images: [''],
    name: '',
    comment: '',
    tel: '',
    kakaoId: '',
    address: {
      road: '',
      latitude: 0,
      longitude: 0,
    },
    basic: {
      tags: [],
      comment: '',
    },
    class: {
      list: [],
    },
    teacher: {
      list: [],
    },
    verify: {
      partnersUid: '',
      registerDate: '',
      status: 'default',
    },
  },
};

const AcademyDetailContainer = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { academyUid } = useParams();
  const { getAcademyUserWithRegistration, updateAcademyRegistration } =
    useAcademyApi();

  const [academyDetailInfo, setAcademyDetailInfo] =
    useState<GetAcademyUserWithRegistrationType>(academyDummy);

  const addComma = useCallback((num: number) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  }, []);

  const onChangeStatusClicked = useCallback(
    async (register: string) => {
      if (academyUid) {
        updateAcademyRegistration(academyUid, register);
        navigate(-1);
      }
    },
    [academyUid, updateAcademyRegistration],
  );

  const onPartnersButtonClicked = useCallback(() => {
    navigate(`/partners/detail/${academyDetailInfo.user.uid}`);
  }, [academyDetailInfo]);

  useEffect(() => {
    const loadAcademyUser = async () => {
      if (academyUid) {
        setAcademyDetailInfo(
          (await getAcademyUserWithRegistration(academyUid)) ?? academyDummy,
        );
      }
    };
    loadAcademyUser();
  }, [academyUid]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  return (
    <AcademyDetail
      academyDetailInfo={academyDetailInfo}
      addComma={addComma}
      onChangeStatusClicked={onChangeStatusClicked}
      onPartnersButtonClicked={onPartnersButtonClicked}
    />
  );
};

export default AcademyDetailContainer;
