import CPageNationContainer from '@components/CPageNation/containers/CPageNationContainer';
import { telFomatter } from '@lib/factory';
import { partnersProps } from '@typedef/components/partners/partners.types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/list.css';

const Partners = ({
  setSearchText,
  searchText,
  listBodyItemInfos,
  page,
  setPage,
  maxPage,
  searchRef,
  onSearchClicked,
}: partnersProps) => {
  const navigate = useNavigate();
  return (
    <div className="partners">
      {/* 검색창 */}
      <div
        className="search-wrapper"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            setSearchText(searchRef.current?.value ?? '');
            onSearchClicked();
          }
        }}
      >
        <input
          className="search-input"
          type="text"
          id="inputId"
          placeholder={'고객명으로 검색해주세요'}
          autoComplete={'off'}
          ref={searchRef}
        />
        <div
          className="image-container"
          onClick={() => {
            setSearchText(searchRef.current?.value ?? '');
            onSearchClicked();
          }}
        >
          <img
            className="image"
            src="/assets/images/ic_search.png"
            alt="ic_icon"
          />
        </div>
      </div>

      {/* 리스트 헤더 */}
      <div className="list-header">
        <div className="name text">이름</div>
        <div className="email text">이메일</div>
        <div className="tel text">전화번호</div>
        <div className="date text">가입일</div>
        <div className="academy-num text">보유 학원수</div>
      </div>

      {/* 리스트 바디 */}
      <div className="list-body">
        {listBodyItemInfos.map((item, idx) => {
          const dt = new Date(item.create_date.slice(0, 10));
          return (
            <div
              key={idx}
              className="list-items"
              onClick={() => navigate(`../detail/${item.uid}`)}
            >
              <div className="partners-name item">{item.name}</div>
              <div className="email item">{item.email}</div>
              <div className="tel item">{telFomatter(item.tel)}</div>
              <div className="join-date item">{`
              ${dt.getFullYear()}-
              ${dt.getMonth() + 1}-
              ${dt.getDate()} 
              `}</div>
              <div className="academy-num item">{item.academy_count}</div>
            </div>
          );
        })}
      </div>

      {/* 리스트 footer */}
      <div className="list-footer">
        {/* 리스트 페이지네이션 */}
        <CPageNationContainer
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          onPageChanged={setPage}
        />
      </div>
    </div>
  );
};

export default Partners;
