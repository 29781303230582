import { TokenActionTypes } from './actions';
import { UPDATE_TOKEN_ACTION } from './modules/actionTypes';

type TokenStateType = {
  token: string;
};

const initState: TokenStateType = {
  token: '',
};

const tokenReducer = (
  state: TokenStateType = initState,
  { type, payload }: TokenActionTypes,
): TokenStateType => {
  switch (type) {
    case UPDATE_TOKEN_ACTION:
      return {
        token: payload as string,
      };
    default:
      return state;
  }
};

export default tokenReducer;
