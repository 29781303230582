import { LoginProps } from '@typedef/components/login/login.types';
import React from 'react';
import './css/index.css';

type Props = {
  onLoginClicked: () => void;
  id: string;
  password: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
};

const Login = ({ onLoginClicked, id, password, setId, setPassword }: Props) => {
  return (
    <div className="login">
      <div className="login-wrapper">
        <div className="image-container">
          <img
            className="image"
            src="/assets/images/banner.png"
            alt="ic_logo"
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onLoginClicked();
          }}
          className="input-wrapper"
        >
          <input
            className="input"
            type="text"
            name="emailInput"
            id="idInput"
            value={id}
            onChange={(e) => setId(e.target.value)}
            required
            placeholder="아이디를 입력해주세요"
          />
          <input
            className="input"
            type="password"
            name="passwordInput"
            id="passwordInput"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="비밀번호를 입력해주세요"
          />
          <button type="submit" className="login-button">
            로그인
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
