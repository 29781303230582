import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducers from '@store/rootReducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import './styles/css/core.css';

const myStore = createStore(rootReducers, composeWithDevTools());

ReactDOM.render(
  <Provider store={myStore}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
