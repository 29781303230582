import PartnersContainer from '@components/Partners/containers/PartnersContainer';
import PartnersDetailContainer from '@components/Partners/containers/PatrnersDetailContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const PartnersRouting = () => {
  return (
    <Routes>
      <Route path="list" element={<PartnersContainer />} />
      <Route path="detail/:partnersUid" element={<PartnersDetailContainer />} />
    </Routes>
  );
};

export default PartnersRouting;
