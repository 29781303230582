import { SidebarListItem } from '@typedef/components/sidebar/sidebar.types';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBar from '../SideBar';

const sidebarList = [
  {
    root: 'partners',
    title: '파트너스',
    link: '/partners/list',
  },
  {
    root: 'academy',
    title: '학원',
    link: '/academy/list',
  },
];

const SideBarContainer = () => {
  const navigation = useNavigate();

  const onSideBarItemClicked = useCallback((i: number) => {
    navigation(sidebarList[i].link);
  }, []);
  return (
    <SideBar
      sidebarList={sidebarList as SidebarListItem[]}
      onSideBarItemClicked={onSideBarItemClicked}
    />
  );
};

export default SideBarContainer;
