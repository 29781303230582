import React from 'react';
import './styles/cpage.nation.styles.css';

type Props = {
  currentPage: number;
  pageList: number[];
  onPageClicked: (page: number) => void;
  onBackBtnClicked: () => void;
  onNextBtnClicked: () => void;
};

const CPageNation = ({
  currentPage,
  pageList,
  onPageClicked,
  onBackBtnClicked,
  onNextBtnClicked,
}: Props) => {
  return (
    <div className="table-pagenation">
      <div
        className="pagenation-item pagenation-back-btn"
        onClick={onBackBtnClicked}
      >
        <img src="/assets/images/ic_prev.svg" alt="ic_prev" />
      </div>
      {pageList.map((item, idx) => (
        <div
          key={idx}
          className={`pagenation-item ${item === currentPage && 'selected'}`}
          onClick={() => onPageClicked(item)}
        >
          {item}
        </div>
      ))}
      <div
        className="pagenation-item pagenation-next-btn"
        onClick={onNextBtnClicked}
      >
        <img src="/assets/images/ic_next.svg" alt="ic_next" />
      </div>
    </div>
  );
};

export default CPageNation;
