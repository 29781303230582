export const telFomatter = (tel: string) => {
  if (tel.length === 11) {
    return tel.replace(/(\d{3})(\d{4})(\d{4})/, `$1-$2-$3`);
  } else if (tel.length === 8) {
    return tel.replace(/(\d{4})(\d{4})/, `$1-$2`);
  } else {
    if (tel.indexOf('02') === 0) {
      return tel.length === 9
        ? tel.replace(/(\d{2})(\d{3})(\d{4})/, `$1-$2-$3`)
        : tel.replace(/(\d{2})(\d{4})(\d{4})/, `$1-$2-$3`);
    } else {
      return tel.replace(/(\d{3})(\d{3})(\d{4})/, `$1-$2-$3`);
    }
  }
};
