import { RootState } from '@store/rootReducers';
import { udpateTokenAction } from '@store/token/actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useToken() {
  const token = useSelector((root: RootState) => root.tokenReducer.token);

  const dispatch = useDispatch();

  const __updateToken = useCallback(
    (token: string) => {
      dispatch(udpateTokenAction(token));
    },
    [dispatch],
  );

  return {
    token,
    __updateToken,
  };
}
