import { GetAcademyUserWithRegistrationType } from '@typedef/hooks/api/use.academy.types';
import React from 'react';
import './css/verifyStatus.css';

interface Props {
  academyDetailInfo: GetAcademyUserWithRegistrationType;
  onChangeStatusClicked: (register: string) => void;
}

const AcademyVerifyStatus = ({
  academyDetailInfo,
  onChangeStatusClicked,
}: Props) => {
  return (
    <div className="academy-verify-status">
      {/* 상단 학원 이름, 위치 */}
      <div className="academy-info">
        <h1 className="info-name">{academyDetailInfo.academy.name}</h1>
        <div className="info-address">
          {academyDetailInfo.academy.address.road}
        </div>
      </div>
      {/* 프로필 박스 */}
      <div className="academy-profile-container">
        <div className="profile-img-container">
          {academyDetailInfo.user.image !== 'default' && (
            <img
              src={academyDetailInfo.user.image}
              alt="프로필사진"
              className="profile-img"
            />
          )}
        </div>
        <div className="profile-info-container">
          <div className="column">
            <div className="profile-info ">
              <div className="profile-title">이름</div>
              <div className="profile-content">
                {academyDetailInfo.user.name}
              </div>
            </div>
            <div className="profile-info ">
              <div className="profile-title">이메일</div>
              <div className="profile-content">
                {academyDetailInfo.user.email}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="profile-info ">
              <div className="profile-title">전화번호</div>
              <div className="profile-content">
                {academyDetailInfo.user.tel}
              </div>
            </div>

            <div className="profile-info ">
              <div className="profile-title">가입일</div>
              <div className="profile-content">
                {academyDetailInfo.user.create_date.slice(0, 10)}
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <button
            className="approve"
            onClick={() => onChangeStatusClicked('approved')}
          >
            {'승인'}
          </button>
          <button
            className="refuse"
            onClick={() => onChangeStatusClicked('approve-denied')}
          >
            {'승인 거절'}
          </button>
        </div>
      </div>
      {/* 이미지 박스 */}
      <div className="academy-img-container">
        <div className="academy-img-wrapper">
          <span className="academy-img-title">{'인증서류'}</span>
          <img
            className="academy-img"
            src={academyDetailInfo.academy.verify?.businessLicense}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AcademyVerifyStatus;
