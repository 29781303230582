import {
  apiOrigin,
  API_ROUTE,
  BasicApiResponseType,
  reqeustGet,
  reqeustPut,
  reqeustSecureGet,
  reqeustSecurePost,
} from '@lib/api';
import {
  GetAcademyListByKeywordResponseType,
  GetAcademyUserWithRegistrationType,
} from '@typedef/hooks/api/use.academy.types';
import { useCallback } from 'react';
import useToken from './token/useToken';

export default function useAcademyApi() {
  const { token } = useToken();

  const getAcademyRegisterPrice = useCallback(async () => {
    const {
      data,
      message,
      config: { response },
    } = await reqeustGet<
      BasicApiResponseType<{ price: number; account: string }>
    >('https://backend.wim.kro.kr:6004/api/basic/data/academy', {});
    if (!response) {
      console.log(response, message);
      return;
    }

    return data;
  }, []);

  const updateAcademyRegisterPrice = useCallback(
    async (price: number, account: string) => {
      const {
        data,
        message,
        config: { response },
      } = await reqeustPut<BasicApiResponseType<{}>>(
        apiOrigin + API_ROUTE.academy.updateAcademyRegisterPrice,
        { Authorization: `Bearer ${token}` },
        { price: price, account: account },
      );

      if (!response) {
        console.log(response, message);
        return;
      }
    },
    [token],
  );

  const getAcademyListAll = useCallback(
    async (count: number, page: number, option: string) => {
      const optionParams = () => {
        if (option === '') {
          return '';
        } else {
          return `&option=${option}`;
        }
      };

      const {
        data,
        message,
        config: { response },
      } = await reqeustSecureGet<
        BasicApiResponseType<GetAcademyListByKeywordResponseType>
      >(
        apiOrigin +
          API_ROUTE.academy.getAcademyListByKeyword +
          `count=${count}&page=${page}` +
          optionParams(),
        {},
        token,
      );
      if (!response) {
        console.log(response, message);
        return;
      }

      return data;
    },
    [token],
  );

  const getAcademyListByKeyword = useCallback(
    async (count: number, page: number, keyword: string, option: string) => {
      const optionParams = () => {
        if (option === '') {
          return '';
        } else {
          return `&option=${option}`;
        }
      };

      const {
        data,
        message,
        config: { response },
      } = await reqeustSecureGet<
        BasicApiResponseType<GetAcademyListByKeywordResponseType>
      >(
        apiOrigin +
          API_ROUTE.academy.getAcademyListByKeyword +
          `count=${count}&page=${page}&keyword=${keyword}` +
          optionParams(),
        {},
        token,
      );
      if (!response) {
        console.log(response, message);
        return;
      }

      return data;
    },
    [token],
  );

  const getAcademyUserWithRegistration = useCallback(
    async (academyUid: string) => {
      const {
        data,
        message,
        config: { response },
      } = await reqeustSecureGet<
        BasicApiResponseType<GetAcademyUserWithRegistrationType>
      >(apiOrigin + `/academy/${academyUid}/detail`, {}, token);
      if (!response) {
        console.log(response, message);
        return;
      }

      return data;
    },
    [token],
  );

  const updateAcademyRegistration = useCallback(
    async (academyUid: string, register: string) => {
      const {
        data,
        message,
        config: { response },
      } = await reqeustPut<
        BasicApiResponseType<GetAcademyUserWithRegistrationType>
      >(
        apiOrigin + `/academy/${academyUid}/register?status=${register}`,
        {
          Authorization: `Bearer ${token}`,
        },
        {},
      );
      if (!response) {
        console.log(response, message);
        return;
      }

      console.log('update academy registration', response);
    },
    [token],
  );

  return {
    getAcademyListAll,
    getAcademyRegisterPrice,
    updateAcademyRegisterPrice,
    getAcademyListByKeyword,
    getAcademyUserWithRegistration,
    updateAcademyRegistration,
  };
}
