import AcademyVerifyStatusContainer from '@components/Academy/containers/AcademyVerifyStatusContainer';
import AcademyContainer from '@components/Academy/containers/AcademyContainer';
import AcademyDetailContainer from '@components/Academy/containers/AcademyDetailContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AcademyRouting = () => {
  return (
    <Routes>
      <Route path="/list" element={<AcademyContainer />} />
      <Route path="/detail/:academyUid" element={<AcademyDetailContainer />} />
      <Route
        path="/verify/:academyUid"
        element={<AcademyVerifyStatusContainer />}
      />
    </Routes>
  );
};

export default AcademyRouting;
