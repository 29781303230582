import { combineReducers } from 'redux';
import tokenReducer from '@store/token/reducers';

const rootReducers = combineReducers({
  tokenReducer,
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
