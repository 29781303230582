import { telFomatter } from '@lib/factory';
import { PartnersDetailProps } from '@typedef/components/partners/partnersDetail.types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/detail.css';

const PartnersDetail = ({
  partnersInfo,
  statusKorean,
}: PartnersDetailProps) => {
  const navigate = useNavigate();
  return (
    <div className="partners-detail">
      {/* 파트너스 정보 */}
      <div className="partners-info">
        <div className="image-container">
          {partnersInfo.user.image !== 'default' && (
            <img className="profile-img" src={partnersInfo.user.image} />
          )}
        </div>
        <div className="column-container">
          <div className="column">
            <div className="info">
              <span className="name text">이름</span>
              <span className="partners-name info-text">
                {partnersInfo.user.name}
              </span>
            </div>
            <div className="info">
              <span className="text">이메일</span>
              <span className="info-text">{partnersInfo.user.email}</span>
            </div>
          </div>
          <div className="column">
            <div className="info">
              <span className="text">전화번호</span>
              <span className="info-text">
                {telFomatter(partnersInfo.user.tel)}
              </span>
            </div>
            <div className="info">
              <span className="text">가입일</span>
              <span className="info-text">
                {partnersInfo.user.create_date.slice(0, 10)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* 리스트 헤더 */}
      <div className="list-header">
        <div className="academy-name text">학원이름</div>
        <div className="address text">학원주소</div>
        <div className="lecture-num text">강의수</div>
        <div className="teacher-num text">강사수</div>
        <div className="status text">상태</div>
      </div>

      {/* 리스트 바디 */}
      <div className="list-body">
        {partnersInfo.academy.total > 0 ? (
          partnersInfo.academy.list.map((item) => {
            return (
              <div
                className="list-items"
                key={item.uid}
                onClick={() => navigate(`/academy/detail/${item.uid}`)}
              >
                <div className="academy-name item">{item.name}</div>
                <div className="address item">{item.address.road}</div>
                <div className="lecture-num item">
                  {item.class?.list.length}
                </div>
                <div className="teacher-num item">
                  {item.teacher?.list.length}
                </div>
                <div className="status item">
                  {statusKorean(item.verify?.status ?? 'default')}
                </div>
              </div>
            );
          })
        ) : (
          <div>등록된 학원이 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default PartnersDetail;
