import useToken from '@hooks/token/useToken';
import usePartnersApi from '@hooks/usePartnersApi';
import { VerifyStatusType } from '@typedef/components/common/common.types';
import {
  PartnersDetailItem,
  PartnersInfoType,
} from '@typedef/components/partners/partnersDetail.types';
import { GetAcademyUserWithRegistrationType } from '@typedef/hooks/api/use.academy.types';
import { GetPartnersDetailType } from '@typedef/hooks/api/use.partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PartnersDetail from '../PartnersDetail';

const academyDummy: GetPartnersDetailType = {
  user: {
    uid: '',
    email: '',
    name: '',
    tel: '',
    image: '',
    create_date: '',
  },
  academy: {
    total: 0,
    list: [
      {
        uid: '',
        averagePrice: 0,
        images: [''],
        name: '',
        comment: '',
        tel: '',
        kakaoId: '',
        address: {
          road: '',
          latitude: 0,
          longitude: 0,
        },
        basic: {
          tags: [],
          comment: '',
        },
        class: {
          list: [],
        },
        teacher: {
          list: [],
        },
        verify: {
          partnersUid: '',
          registerDate: '',
          businessLicense: '',
          academyRegisterCertification: '',
          status: 'default',
        },
      },
    ],
  },
};

const PartnersDetailContainer = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { partnersUid } = useParams();
  const { getPartnerDetail } = usePartnersApi();
  const [partnersInfo, setPartnersInfo] = useState(academyDummy);

  const statusKorean = useCallback((status: VerifyStatusType) => {
    switch (status) {
      case 'approved':
        return '승인';
      case 'approve-denied':
        return '승인 거절';
      case 'approve-ready':
        return '승인 대기';
      default:
        return '';
    }
  }, []);

  useEffect(() => {
    const loadDetail = async () => {
      if (partnersUid) {
        const list = await getPartnerDetail(partnersUid);
        if (list) {
          setPartnersInfo(list);
        }
      }
    };
    loadDetail();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token]);

  return (
    <PartnersDetail partnersInfo={partnersInfo} statusKorean={statusKorean} />
  );
};

export default PartnersDetailContainer;
